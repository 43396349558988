const Fr = {
	welcomeMsg: "Bienvenue",
	// Menu left -- sidebar
	LeadSources: "Sources de leads",
	MySources: "Mes sources",
	NewSources: "Nouvelle source",
	LeadManagement: "Gestion de leads",
	MyLeads: "Mes leads",
	Qualification: "Qualification",
	Analytics: "Statistiques",
	Settings: "Paramètres",
	Workspace: "Espace de travail",
	LeadStatus: "Statuts",
	Members: "Utilisateurs",

	// Menu top -- navbar
	CurrentlyManaging: "Vous gérez actuellement ",
	PopoverChangeWorkSpace:
	"Vous pouvez gérer plusieurs espaces de travail avec une séparation totale des leads et des droits d'accès. Assurez-vous de choisir le bon espace de travail avant de procéder à la gestion de vos leads.",
	Options: "Options",
	NewWorkspace: "Nouvel espace de travail",
	YouHave: "Vous avez",
	Leads: "Leads",
	PlanInUse: "Plan actuel",
	ProfileSettings: "Paramètres de profil",
	WorkspaceSettings: "Paramètres de l’espace de travail",
	HelpCenter: "Centre d’aide",
	Logout: "Déconnexion",
	Upgrade: "Mettre à jour mon plan",
	AddWorkspace: "Créer un espace de travail",
	Name: "Nom",
	TypeYourWorkspaceName: "Tapez le nom de votre espace de travail",
	Timezone: "Fuseau horaire",
	TheTimezoneToBeUsedInThisWorkspace:
	"Le fuseau horaire à utiliser dans cet espace de travail.",
	Country: "Pays",
	CountryDesc:
	"Le champ Pays nous aidera à déterminer le préfixe téléphonique qui sera utilisé au niveau de vos appels.",
	UpgradeDesc:
	"Nous sommes désolés, mais cette fonctionnalité n'est pas disponible dans votre plan actuel",
	UpgradeButton: "Mettre à jour mon plan",
	WelcomeToOhmylead: "Bienvenue à Ohmylead!",
	OptimizeTitle:
	"Pour une expérience optimale, nous vous recommandons de télécharger notre application mobile",
	Ok: "Ok",
	YourAccount:'Profil',

	// Layout Global component
	CheckEmailLink:
	"Veuillez vérifier votre email et cliquer sur le lien d'activation. Si vous n'avez pas reçu l'e-mail de confirmation :",
	RequestAnother: "Cliquez ici pour renvoyer un autre",
	TrialingAgencyPlan:
	"Vous testez maintenant le plan 'Agence'. Vous avez {TrialPeriod} jours pour essayer nos fonctionnalités premium ou ",
	UpgradeNow: "Mettez votre version à jour dès à présent",
	DefaultMonthPrice: "pour seulement 9 $ / mois.",

	// Analytics
	analytics: "Statistiques",
	AllTime: "Toute la période",
	Leadsbystatus: "Leads par statut",
	Leadsbysources: "Leads par source",
	Nodatayet: "Aucune donnée disponible",
	Unfortunately:
	"Malheureusement, aucune donnée n'est actuellement disponible",
	TitleNoteLink: "Vous pouvez essayer de ",
	AddAnotherSource: "Ajouter une autre source",
	YourLeadsFunnel: "Votre entonnoir",
	// My source
	Sync: "Synchnronisez",
	SyncTitle: "Votre source de leads !",
	VisitPage: "Consulter",
	Publishedon: "Publié le ",
	WelcomeEmail: "Email de bienvenue",
	LeadsAlert: "Notifications par e-mail",
	Mapping: "Mapping",
	DeleteSource: "Supprimer la Source",
	NoSource: "Aucune donnée disponible!",
	NoFbCampaign: "Aucune donnée disponible !",
	DeletingConfirg:
	"En supprimant <b>{name}</b>, les leads de cette source seront également supprimés. Voulez-vous vraiment continuer?",
	YesDeleteIt: "Oui",
	// New source
	ConnectFbTitle: "Connecter votre compte Facebook",
	ConnectFbDesc:
	"Synchronisez vos formulaires Facebook avec Ohmylead et recevez vos leads en temps réel ",
	Next: "Suivant",
	SelectLeadTitle: "Ajouter votre formulaire",
	SelectLeadDesc:
	"Sélectionnez la page associée à votre formulaire",
	PleaseWait: "Veuillez patienter.",
	Previous: "Précédent",
	NameYourSource: "Nom de la source:",
	NameSourceTitle: "Veuillez donner un nom à votre source.",
	FieldsMapping: "Mapping des champs",
	ImportExistingLeads: "Importez les leads existants",
	LeadAlert: "Notifications par e-mail",
	Finish: "Terminer",
	ExcelDesc:
	"Qualifiez vos leads en important votre fichier Excel. Attention, votre fichier doit être bien structurer afin d'assurer une bonne synchronisation avec Ohmylead.",
	ExcelTitle:
	"Cliquez sur l'icône ci-dessous pour choisir votre fichier Excel depuis cet ordinateur",
	ImportExcelFile: "Importer Fichier Excel",
	MatchTitle: "Mapping des champs",
	OptionSelect: "Sélectionner un champ (Ignorer)",
	WelcomeEmailTabs:
	"C'est l'email que chaque nouveau lead recevra après avoir rempli le formulaire pour maintenir la chaleur de son besoin et faire une bonne impression d’entame ",
	SenderEmail: "E-mail de l'expéditeur",
	SenderName: "Nom de l'expéditeur",
	Replytoemail: "Répondre à l'email",
	Replytoname: "Répondre au nom",
	Subject: "Sujet",
	Message: "Message",
	SelectAField: "Sélectionnez un champ",
	LeadAlertTitle:
	"C'est les notifications instantanées qui vous avertiront dès qu’un nouveau lead est généré.",
	SendTo: "Envoyer à",

	login_title: "Des leads chauds vous attendent!",
	Email: "Email",
	TypeEmail: "Tapez l'adresse email",
	Password: "Mot de passe",
	login_validate:
	"Ces informations d'identification ne correspondent pas à nos enregistrements..",
	login_account: "Connectez-vous",
	login_accountcheck: "Vous n'avez pas de compte ?",
	ForgotPassword: "Mot de passe oublié ?",
	DeleteWorkspace: "Supprimer l'espace de travail",
	LeaveWorkspace: "Quitter l'espace de travail",
	Yes: "Oui",
	ConfrmDelete: "Voulez-vous vraiment {TypeAction} cet espace de travail",

	FullName: "Nom complet",
	EmailAddress: "Adresse e-mail",
	PhoneNumber: "Numéro de téléphne",
	TimezoneDesc: "Le fuseau horaire à utiliser au niveau de toute l'application.",
	ChangePassword: "Changer le mot de passe",
	PasswordDesc:
	"Tapez votre mot de passe actuel pour enregistrer les modifications",
	Save: "Enregistrer",

	Showing: "Affichant",
	leads: "leads",
	YouHaveSelected: "Vous avez sélectionné",
	BulkActions: "Actions groupées",
	Customize: "Personnaliser",
	ShowHideColumns: "Afficher / Masquer les colonnes",
	ExportCsvFile: "Exporter le fichier csv",
	SyncLeads: "Votre Source de leads",
	Source: "Source",
	Createdon: "Crée le",
	FirstName: "Prénom",
	LastName: "Nom",
	Phone: "Numéro de téléphone",
	AddField: "Ajouter un champ",
	MinusField: "Réduire un champ",
	Delete: "Supprimer",
	activities: "Activités",
	AddNote: "Ajouter une note",
	AddNewLead: "Ajouter un nouveau lead",

	YouWantToDelete: "Voulez-vous supprimer :",
	lead: "lead",
	YesDelete: "Oui, supprimer",
	ConfirmDelete: "Voulez-vous vraiment supprimer?",
	NewLeadReceived: "Lead généré.",
	Details : 'Détails',

	// New ------------------------------------
	DemoDataDesc:
	"Des leads de démonstration ont été ajoutés à votre compte.",
	ClearDemoData: "Cliquez ici pour les effacer",

	Qualify: "Qualifier",
	Unqualify: "Ne pas qualifier",
	Archive: "Archiver",

	BeingVerified: "En cours de vérification",
	NotValid: "Non Valide",
	Valid: "Valide",
	at: "à",
	From: "De",
	to: "à",
	PleaseFillOutThisField: "Veuillez remplir ce champ.",
	Status: "Statuts",
	AddLead: "Ajouter un lead",
	Close: "Fermer",
	DisplayedColumns: "Colonnes affichées",
	Done: "Terminé",
	VerificationIsComplete: "La vérification est terminée",
	HideResults: "Masquer les résultats",
	OfEmailsAreValid: "d'e-mails sont valides",
	OfPhonesAreValid: "de téléphones sont valides",
	OfLeadsAreDuplicate: "de leads sont dupliqués",
	YouSeeHowEasyIts: "Vous voyez comment c'est facile",
	YourTurnNow: "C'est votre tour maintenant",
	SyncYourFirstLeadSource: "Synchronisez votre première source de leads ",
	ShowResults: "Afficher les résultats",
	Back: "Retour",
	DemoDataTopTitle:
	"Centralisez tous vos leads en synhronisant l'ensemble de vos formulaires.",
	DemoDataTitle:
	"Vous pouvez commencer avec des données de démonstration pour voir à quel point il est facile de qualifier vos leads",
	DemoDataStart: "Commencez avec des données de démonstration",
	DemoDataReceive:
	"Recever de nouveaux leads de la source {title} dans votre compte Ohmylead",
	Configure: "Configurer",

	Token: "Jeton",
	Login: "Connexion",
	Send: "Envoyer",
	title_forget:
	"Veuillez vérifier votre messagerie pour réinitialiser votre mot de passe",
	BackToHome: "Retour à l'accueil",
	ForgotYourPassword: "Mot de passe oublié?",
	ForgotPasswordTitle: "Pas de problème, il est très facile de récupérer votre mot de passe",
	BackToLogin: "Retour à la connexion",
	CheckRegister: "Vous n'avez pas de compte?",
	TypeYourNewPassword: "Taper votre nouveau mot de passe",
	Reset: "Réinitialiser",
	StartFreeTrial: "Commencer l'essai gratuit dès aujourd'hui.",
	FirstLastName: "Prénom et nom",
	Hide: "Cacher",
	Show: "Afficher",
	AlreadyHaveAccount: "Avez-vous déjà un compte ?",

	// New & -------------------------------------------------------------

	Connect: "Connecter",
	WhiteLabel: "Marque blanche",
	StartNow: "Commencez maintenant",
	LastStepDescription:
	"NB: Pour que votre sous-domaine soit correctement lié à votre compte Ohmylead, veuillez vous connecter à la zone DNS de votre registraire de sous-domaine et ajouter un <b> enregistrement A </b> qui pointe vers votre sous-domaine (ou www si vous associez un nom de sous-domaine) à <b> 161.35.80.116 </b> Nous vous recommandons d'ajouter l'enregistrement DNS avant de valider votre sous-domaine ici. Si vous ne savez pas comment faire cela, veuillez nous contacter, nous serons heureux de vous aider.",

	OnlyAgencyPlan: "Plan d'agence uniquement",
	CompanyInformation: "Informations sur l’entreprise",
	CompanyName: "Nom de l'entreprise",
	FromName: "Nom de l'expéditeur",
	FromEmail: "E-mail de l'expéditeur",
	Logo: "Logo",
	CurrentLogo: "Logo Actuel",
	Favicon: "Favicon",
	CurrentFavicon: "Favicon Actuel",
	Domains: "Votre sous-domaine",
	OneLastStep: "Dernière étape",
	AddYourOwnDomain: "Ajoutez le sous domaine de l'application web",
	AddYourOwnDomainMobile: "Ajoutez le sous domaine de l'application mobile",

	AddYourOwnDomainDesc: "Sous-domaine web", // starting with <b>“https://”</b> or <b>“http://”</b>
	AddYourOwnDomainDescMobile: "Sous-domaine mobile", 
	Domain: "Sous-domaine",
	Pending: "En cours",
	Action: "Action",
	SaveChanges: "Enregistrer",
	AddNewMember: "Ajouter un nouvel utilisateur",
	NoDataToDisplay: "Aucune donnée à afficher",
	InProcess: "En cours",
	ResendInvitation: "Renvoyer l'invitation",
	CancelInvitation: "Annuler l'invitation",
	EmailAddressRequired: "Adresse e-mail requise",
	WorkspaceDesc:
	"Cette personne recevra une demande par e-mail pour accepter votre invitation",
	YouWantToRemove: "Souhaitez-vous supprimer",
	InvitationMemberDesc: "Voulez-vous vraiment supprimer cette invitation?",
	PayAsYouGrow: "Choisissez le plan qui vous convient le mieux",
	PayPlanDesc:
	"Notre tarification est simple, transparente et adaptée aux solopreneurs, agences <br> et entreprises de toutes tailles souhaitant gérer efficacement leurs leads",
	MonthlyBilling: "Facturation mensuelle",
	AnnualBilling: "Facturation annuelle",

	CurrentPlan: "Plan actuel",
	Per: "Par",
	Confirm: "Confirmer",
	ChangePlanTo: "Changer le Plan en :",
	QuestionContinue: "Etes-vous sûr de vouloir continuer ?",

	LeadStatusDesc:
	"Les statuts définissent l'état et la qualité des leads générés.",
	StatusLabel: "Statuts",
	IsQualified: "Est qualifié",
	Default: "Par défaut",
	SpecialCharacters:
	"Les caractères spéciaux ne sont pas autorisés ",

	DeleteStatus: "Supprimer le statut",
	EditStatus: "Modifier le statut",
	AddCustomStatus: "Ajouter un statut personnalisé",
	FacebookToken: "Jeton Facebook ",
	Expired: "Expiré",
	Regenerate: "Régénérer",

	PageName: "Nom de la page",
	GeneratedBy: "Généré par",
	CreatedAt: "Créé à",
	Actions: "Actions",
	WorkspaceName: "Nom de l’espace de travail",

	TimezoneWorkspace: "Le fuseau horaire à utiliser dans cet espace de travail.",
	CountryWorkspace:
	"Le champ Pays nous aidera à déterminer le préfixe téléphonique qui sera utilisé ai niveau de vos appels.",

	// new -------------------
	Start: "Démarrer",
	Edit: "Modifier",
	View: "Voir",
	Success: "Succèss",
	Checking: "Vérification",
	Reconfig: "Réinitialiser",
	AdminDashboard: "Tableau de bord d'administration",
	ErrorLoadUsers: "Erreur, impossible de récupérer la liste des utilisateurs.",

	// Agency Deal -----------------

	AbsolutelyFreeToTry: "Essai Absolument GRATUIT ",
	Message1:
	"Pas de surprise. Testez-le par vous-même pendant 14 jours c’est gratuit.",
	CancelOrDowngradeAnytime: "Annulez ou rétrogradez à tout moment",
	Message2:
	"Vous pouvez mettre à niveau, rétrograder ou annuler votre abonnement à tout moment.",
	SatisfiedOrMoneyBack: "Satisfait ou remboursé",
	Message3:
	"Recevez votre remboursement en moins de 24h, après avoir utilisé notre logiciel pendant 30 jours.",
	SignupAgency:
	"Obtenez la marque blanche gratuitement en souscrivant à un plan annuel",
	LeadsFor: "leads pour",
	YearOnly: "Année seulement",
	YourFullName: "Votre nom complet",
	SyncAndKeep: "Synchroniser & Sauvegarder",
	GetStarted: "Commencer",
	Review1:
	"Bien sûr, vous avez 100 000 e-mails, mais sont-ils vraiment RÉELS? Ohmylead m’a aidé à nettoyer ma liste de diffusion et passer à 80 000 grâce à leur outil de vérification automatique des emails et numéros de téléphone ",
	Review2:
	"C'est comme un contrôle du trafic aérien pour vos leads - Ohmylead garantit qu'aucun lead ne passe entre les mailles du filet et que chacun de vos prospects obtienne une réponse rapide et personnalisé",
	Review3:
	"Mes commerciaux ne traitent, désormais, que des leads qualifiés! Tous mes formulaires sont synchronisés et tous les leads entrants sont qualifiés et envoyés, dépendamment de leur statut, vers mon CRM et/ou vers mon outil de mailing automatisé. J’en avais rêvé il y a un temps. Ohmylead l’a fait! ",

	// Free Register -----------------------
	YourEmailAddress: "Votre email",
	TypeYourPassword: "Votre mot de passe",
	WelcomeOnBoard: "Bienvenue à bord !",

	//Login -----------------------------------
	YourWorkEmail: "Votre e-mail professionnel",
	WelcomeBack: "Nous sommes content de vous revoir!",
	Or: "ou",

	// Register ------------------------------
	YouInvited: "Vous avez été invité",
	HasInvitedYou: "Vous a invité",
	ToJoin: "Rejoindre",
	NameLenghtError: "Le nom doit contenir au moins 3 caractères.",
	EmailNotValid: "L'adresse e-mail n'est pas valide.",
	PasswordLenghtError:
	"Le mot de passe doit contenir au moins 8 caractères.",
	PhoneNotValid: "Ce numéro de téléphone n'est pas valide.",
	EmailAlreadyUsed: "Cette adresse e-mail est déjà utilisée",

	//TrialRegister -----------------------------------

	StartYourFreeTrial: "Commencez votre essai GRATUIT de 7 jours",
	YourTrialUntil:
	"Votre essai de 7 jours est <b> 100% gratuit </b>, commence aujourd'hui et dure jusqu'au <b> {jusqu'à} </b>. Annulez à tout moment.",
	YouHaveChosen: " Vous avez choisi le",
	Plan: "Plan",
	WhatsIncluded: "Ce qui est inclus",
	SoloFeatures:
	'<li><i class="fa fa-check" style="font-size: 14px;"></i> Formulaires facebook illimités </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Champs personnalisés illimités </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Validation des emails et téléphones </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Notifications illimitées </li><li><i class="fa fa-check" style="font-size: 14px;"></i> E-mails de bienvenue illimités </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Importation et exportation Excel </li>',
	TeamFeatures:
	'<ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Formulaires facebook illimitées</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Champs personnalisés illimités</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Validation des emails et téléphones</li><li><i class="fa fa-check" style="font-size: 14px;"></i>  Notifications illimitées </li><li><i class="fa fa-check" style="font-size: 14px;"></i> E-mails de bienvenue illimités</li></ul><ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Importation et exportation Excel </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Utilisateurs illimités </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Leads illimités / jour</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Support Prioritaire</li></ul>',
	AgencyFeatures:
	'<ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Formulaires facebook illimitées</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Champs personnalisés illimités</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Validation des emails et téléphones </li><li><i class="fa fa-check" style="font-size: 14px;"></i>  Notifications illimitées  </li><li><i class="fa fa-check" style="font-size: 14px;"></i> E-mails de bienvenue illimités </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Importation et exportation Excel</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Utilisateurs illimités</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Leads illimités / jour</li></ul><ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Support Prioritaire</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Marque blanche</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Espaces de travail illimités </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Integrations personnalisées </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Séances de coaching </li><li><i class="fa fa-check" style="font-size: 14px;"></i> Spécialiste dédié</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Examen périodique du compte </li></ul>',
	DueToday: "Dû aujourd'hui",
	Then: "Ensuite",
	After7Days: "Après 14 jours",
	MoneyBackGuarantee: "Garantie de remboursement à 100%!",

	//Checkout ------------------------------------------------------------------
	SubscribeToSolo: "Abonnez-vous au plan 'Solo'",
	SubscribeToTeam: "Abonnez-vous au plan 'Équipe'",
	SubscribeToAgency: "Abonnez-vous au plan 'Agence'",

	// CRMs ----------------------------------------------------------------------

	CRMsSearch: "Trouver votre CRM",
	
	
	

	//  ESP --------------------------------------------------------------------

	FindYourEsp: "Trouver votre ESP",

	// Lead Enrichement -------------------------------------------------------

	ConnectYourAccount: "Connecter votre compte{ tools }",
	YourAPIKey: "Votre clé API",
	PleaseFillField: "Veuillez remplir ce champ.",
	APIKey: "Clé API",
	ConnectedSuccessfully: "Connecté avec succès..",
	ApiKeyRequired: "Clé Api Requise !",
	Disconnected: "Déconnecté..",

	// Voip ----------------------------------------
	
	// MyLeads ----------------------------------------------------------

	Filter: "Filtre",
	Title: "Titre",
	Statuses: "Statuts",
	Search: "Recherche",
	SendSMSViaVoip:
	"Envoyez un SMS via votre compte <strong> Voip préféré </strong> pour engager vos leads",
	WriteNoteLead: "Ajouter une note",
	EnterNameField: "Saisissez le nom du champ",
	EnterValueField: "Saississez la valeur du champ",
	GeneratedOn: "Généré le",
	Sources: "Toutes les sources",
	EmailValid: "Email Valide",
	PhoneValid: "Téléphone Valide",
	ChangesSaved: "Changements sauvegardés",
	FieldNoteRequired: "Champ non requis !",
	LeadHasBeenSent: " Votre Lead a été envoyé ! ",
	LeadsHaveBeenSent: "Vos leads ont été énvoyés! ",
	StatusChanged: "Statut modifié !",
	LeadDeleted: "Lead Supprimé !",
	DaysAgo: " Jours",
	Today: " Aujourd’hui ",
	New: "Nouveau ",
	MonthAgo: " Mois",
	MonthsAgo: " Mois",
	YearAgo: " an",
	YearsAgo: " ans",
	FieldRequired: "Champ requis!",
	EmailOrPhoneValid: "L'email ou le téléphone doit être validé !",
	duplicate:"Doublon",
	duplicates:"Doublons",
	mark_as_a_duplicate:"Marquer comme doublon",
	lead_details:"Fiche lead",
	send_email:"Envoie d’email",
	send_sms:"Envoyer SMS",
	next:"Suivant",
	previous:"Précédent",
	email_lead_subject : "Objet",
	email_lead_to : "À",
	email_lead_from : "De",
	email_lead_template : "Template",
	email_lead_choose : "Choisissez",
	email_add_template : "Sélectionnez",
	email_lead_discard: "Annuler",
	email_lead_send : "Envoyer",
	email_sent      : "E-mail a été envoyé au lead",
	title_required: "Titre obligatoire",
	subject_required: "Objet obligatoire",
	body_required: "Description  obligatoire",
	ConfrmDeleteLeads: "Voulez-vous vraiment supprimer ces leads ?",

	//CMTP
	provider_name_required:"Nom du fournisseur est obligatoire",
	provider_name:"Nom du fournisseur",
	add_provider:"Ajouter Fournisseur",
	smtp_provider:"Fournisseur",
	smtp_port_number:"Port du serveur SMTP",
	smtp_port_number_here:"Port du serveur SMTP",
	smtp_port_required:"Port du serveur SMTP est obligatoire",
	smtp_server:"Serveur SMTP",
	example:"Par exemple: smtp.mysmtp.com",
	smtp_server_required: "Serveur SMTP est obligatoire",
	smtp_user_name_required: "Nom d'utilisateur est obligatoire",
	smtp_user_name: "Nom d'utilisateur",
	smtp_email: "Email",
	smtp_email_required: "Email est obligatoire",
	need_help_to_find_SMTP_server_and_port_number: "Besoin d'aide pour trouver le serveur SMTP et le port ?",
	click_here_to_get_more_information:"Cliquez ici pour avoir plus d'informations.",
	smtp_password: "Mot de passe",
	smtp_password_required: "Mot de passe est obligatoire",
	edit_provider: "Editer Fournisseur",
	services_SMTP: "Services SMTP",
	smtp_update: "Modifier",
	smtp_cancel: "Annuler",
	smtp_edit: "Editer",
	smtp_delete: "Supprimer",
	smtp_provider_listing: "Fournisseur",
	smtp_email_listing: "Email",
	smtp_status_listing: "Statut",
	voulez_vous_vraiment_supprimer: "Voulez vous vraiment supprimer?",
	smtp_yes_confirmation: "smtp confirmer",
	smtp_save: "Enregistrer smtp",
	add_smtp_provider:"Ajouter un Fournisseur SMTP",
	update_smtp_provider:"Modifier Fournisseur SMTP",


	// Qualification --------------------------------------------------
	HandleInbound:
	"Gérer les appels téléphoniques entrants / sortants, documenter et traiter les demandes de service client, assurer la liaison entre les équipes d'expédition et sur le terrain.",
	ClickToStart: "Cliquez pour commencer",
	YouHaveToQualify: "Vous avez 04m: 47s pour qualifier ce lead.",
	Score: "Score",
	InOrderToUse:
	"Pour utiliser cette fonction, veuillez connecter votre compte Voip préféré et commencer à composer tout de suite!",
	CallUsingYour: "Appeler en utilisant votre application par défaut",
	Template: "Template",
	Choose: "Choisir",
	Discard: "Rejeter",
	ConnectyourEnrichmentProvider: "Connecter votre fournisseur de lead enrichment",
	IntegrationMessage:
	"Cette intégration vous aide à transformer des identités partielles en profils complets avec des informations telles que son emploi, son profil LinkedIn et Twitter, sa localisation, son âge, son sexe, ses intérêts, etc.",
	LeadEnrichmentIntegrations: "Intégrations d'enrichissement de leads",
	SendYourSMSViaVoip:
	"Envoyez un SMS via votre compte <strong> VoIP préféré </strong> pour engager votre lead",

	// Facebook LeadAds

	Page: "Page",
	SelectYourFacebookLeadAdForm:
	"Sélectionnez votre Facebook Lead AdForm",
	LeadSourceName: "La Source aura le même nom",
	PleasProvideNameSource: "Veuillez saisir un nom pour cette Source.",
	PageNoLeadAd:
	"Nous n'avons trouvé aucun formulaire lié à cette page. Veuillez choisir une autre page ou lancer d'abord votre publicité à formulaire .",
	NoLeadSoFar: "( Pas de leads pour l'instant)",
	Welcome: "Bienvenue",

	// Gravity Forms
	ConnectYourGravityForms: "Connecter votre formulaire",
	BeforeStarting:
	"Avant de commencer à utiliser votre formulaire. Vous devez lier notre plugin à votre site Web WordPress.",
	Steps: "Etapes",
	OurPlugin:
	"à accomplir pour y arriver. Commencez par télécharger notre plugin ",
	DownloadOurPlugin: "Téléchargez notre plugin",
	RememberActivate:
	"Rappel : Installer le plugin Ohmylead pour que cela fonctionne ",
	SaveAndContinue: "Sauvegarder et Continuer",
	WebhookLink: "Webhook Lien",
	CopyLink: "Lien copié !",
	Instructions: "Instructions",
	GravityInstructions:
	'<p> Une fois que vous avez connecté vos <strong> Gravity Forms </strong> à <strong> Ohmylead </strong>, accédez au tableau de bord Gravity Forms et sélectionnez Formulaires. <br> Dans la liste des formulaires, sélectionnez Paramètres du formulaire et enfin cliquez sur <strong> Ohmylead </strong> <br> <br> <img src = "/ images / Integration / sources / gravityFormStep / step1.png" alt = "" class = "doc-img" /> </ p > <p> Une fois sur la page Ohmylead Feeds, cliquez sur <strong> Ajouter nouveau </strong> <br> <br> <img src = "/ images / Integration / sources / gravityFormStep / step2.png" alt = "" class = "doc-img" /> </p> <p> Remplissez la section de cartographie et faites correspondre les champs pour passer à la section suivante. <br> <br> <img src = "/ images / Integration / sources / gravityFormStep / step3.png "alt =" "class =" doc-img "/> </p> <p> Mettez à jour les paramètres et commencez à qualifier vos prospects! <br> <br> <img src =" / images / Integration / sources / gravityFormStep / step4.png "alt =" "class =" doc-img "/> </p> ',
	ReturnToLeadList: "Revenir à la liste des sources de leads",
	DownloadPlugin: "Télécharger le plugin",
	WebhookLinkInstructions: "Lien Webhook & Instructions",
	WebhookSuccessfullyAdded: "Webhook a été ajouté avec succès",
	instructionWebhook : "<div><b>Les champs prédéfinis sont :</b></div><div>First_name si le champ 'Prénom' existe</div><div>Last_name si le champ 'Nom' existe</div><div>Full_name si le champ 'Non complet' existe</div><div>Email si le champ 'Email' existe </div><divTelephone si le champ 'Téléphone' existe</div><div>Vous avez la possibilité de choisir des champs supplémentaires qui seront enregistrés sous forme de champs personnalisés.</div>",

	// Export EXCEL

	CustomField: "Champ personnalisé",
	MapYourFields: "Mappez vos champs !",

	// Web Hook API
	CreateYourSource: "Créer votre source",

	//New Source
	ConnectYourLeadSource: "Connecter votre Source de leads",

	//ClietSettings
	YourWorkspaceEdited: "Votre espace de travail a été modifié avec succès",

	//Dispatch
	DispatchSettings: "Paramètres d’envoi",
	ChooseTheStatus: "Choisir les statuts à exclure",
	ChooseExclude: "Choisir les sources à exclure",
	PickSomeStatus: "Choisir un statut",
	LeadLockTime: "LeadLockTime",
	MaxNumber: "Nombre maximum de rappel si le lead est injoignable",
	IfUnreachable:
	"si c’est inaccessible, planifier automatiquement le prochain appel après (minute)",
	DispatchCallBacksTo: "Envoyer les rappels à",
	SameAgent: "Même agent",
	Anyone: "N’importe qui",
	WichLeads: "Quels leads à appeler en premier",
	CallOldestFirst: "Appeler le plus ancien en premier",
	CallNewstFirst: "Appeler le plus récent en premier",

	//Email Templates
	MyTemplates: "Mes templates",
	AddTemplate: "Ajouter un template",
	TypeToSearch: "Recherche",
	TypeTemplateName: "Tapez le nom de votre template",
	TheReason: "Objet du mail",
	Body: "Le corps",
	YouWantToDeleteTemplate: "Vous voulez supprimer le template:",
	search_here:"Chercher ici...",
	email_templates:"Templates d'e-mails",
	add_email_template: "Ajouter un template d'e-mail",
	title : "Titre",
	type_your_template_title_here : "Tapez le titre de votre template ici ...",
	write_the_email_reason_here: "Ecrivez le motif de l'e-mail ici ...",
	subject : 'Objet',
	body    : 'Description',
	insert_text_here : "Insérer du texte ici ...",
	template_cancel : "Template Annuler",
	template_save   : "Template Enregistrer",
	SMS_Email_Templates : "SMS Templates",
	template_delete : "Supprimer",
	template_edit : "Editer",
	template_update : "Modifier",
	nothing_here_yet : "Aucun template n'est disponible",
	you_can_create_an_email_template_by_clicking_on_this_button : "Vous pouvez créer un template d'email en cliquant sur ce bouton.",
	new_email_template : "Nouveau template d'e-mail",
	template : "Template",


	//List Client
	ManageWorkspaces: "Gérez les espaces de Travail",
	Workspaces: "Espaces de Travail",
	DoYouWantToDeleteWorkspace:
	"Voulez-vous vraiment supprimer cet espace de travail ?",

	//Profile
	WhyYouDecidedToCancel:
	"Nous sommes désolés d'apprendre que vous souhaitez nous quitter, et nous aimerions savoir si nous pouvons faire quelque chose pour continuer à travailler avec vous !",
	YourResponses:
	"Vos réponses resteront confidentielles et ne seront utilisées à aucune autre fin que la recherche menée par notre société..",
	HowLongService:
	"Depuis combien de temps utilisez-vous notre abonnement / service?",
	JustStarted: "Je viens de commencer",
	UpToSixMonths: "Jusqu'à 6 mois",
	UpToYear: "Jusqu'à 1'année",
	Overall:
	"Dans l'ensemble, dans quelle mesure êtes-vous satisfait de l'abonnement / du service?",
	VerySatisfied: "Très satisfait",
	Satisfied: "Satisfait",
	Neutral: "Neutre",
	Unsatisfied: "Non satisfait",
	WhatAreTheReasons:
	"Quelle était la raison principale de l'annulation de votre abonnement / service?",
	NoLongerNeededIt: "Je n'en ai plus besoin",
	ItDidNotMeetMyNeeds: "Le service était inférieure à mes attentes",
	FoundAlternative: "J’ai trouvé une alternative",
	QualityLessThanExpected: "La qualité a été moins que prévu",
	EaseLessThanExpected: "La facilité d'utilisation était moindre que prévu",
	AccessLessExpected: "L'accès au service a été moindre que prévu",
	CustomerServiceLessThanExpected: "Le service client était moins que prévu",
	Other: "Autre",
	IfOther: "Si Autre, veuillez préciser",
	WhatCouldWeDo:
	"Que pouvons-nous faire pour améliorer notre abonnement / service?",
	Submit: "Envoyer",

	//Scoring
	WhatIsScoring: "Qu'est-ce que le lead scoring?",
	ScoringHtml:
	"<p> La notation des prospects vous permet de déterminer la valeur de vos prospects, en leur attribuant des valeurs en fonction de la façon dont l'entreprise perçoit leur comportement. Par conséquent, cela classera vos prospects en conséquence, qualifiant les meilleurs prospects. </p> <p > La notation des prospects a essentiellement un système de notation basé sur des points qui catégorise vos prospects en trois catégories. Vous pouvez configurer votre score de prospect en ajoutant différentes valeurs pour vous conformer aux objectifs de votre entreprise, tels que la source marketing, le pays, le secteur, le statut du prospect, etc. . </p> ",
	ScoreFor: "Score pour une adresse mail & téléphone valides",
	AddStatus: "Ajouter status",
	SelectStatus: "Sélectionner le statut",
	YourStatus: "Vos Status",
	YourSources: "Vos Sources",
	AddSource: "Ajouter Source",

	//404
	LookLikeYourLost: "On dirait que tu es perdu",
	PageNotExist: "la page que vous recherchez n'existe pas!",
	GoBack: "Revenez en arrière",

	//Pricig
	Pricing: "Facturation",
	PricingDesc:
"Notre tarification est simple, transparente et adaptée aux solopreneurs, agences <br> et entreprises de toutes tailles souhaitant gérer efficacement leurs leads",
	YearlyBilling: "Facturation annuelle (3 mois GRATUITS)",
	SignUpForFree: "Inscrivez-vous gratuitement",
	SignUp:'Inscrivez-vous',
	ContactUs: "Contactez Nous",
	// Menu component
	Dashboard: "Tableau de bord",
	Register: "S'inscrire",

	// drop options
	NoItems: "Aucun élément à afficher",

	// Lead alerts tabs
	NotificationSubject: "Objet de la notification",
	TypeRecipientEmail: "Saisissez l'adresse mail du destinataire",
	EmailAddressWillReplyTo: "L'adresse e-mail répondra à",
	TheSenderEmail: "Le nom de l'expéditeur",
	AvailableTags: "Balise Disponibles à utiliser dans le message",

	//Welcome EmailTabs

	InsertYourMessage: "Insérez votre message",

	Search2: "Rechercher",

	//Reset
	ConfirmPassword: "Confirmer le mot de passe",

	//new 12/20/2020
	//Plugin datatable - myleads
	NoLeadToDisplay: "Aucun lead à afficher! ",
	verificationInProgress: "Verification en cours",
	Copy: "Copier",
	Call: "Appeler",
	WhatIsLeadScoring: "Qu'est-ce que le lead scoring?",
	leadScoringMessage1:
	"Un système de notation basé sur des points qui vous permettra de déterminer quels prospects doivent être qualifiés par ordre de priorité",
	leadScoringMessage2: "Vous pouvez configurer votre système de notation en",
	clickingHere: "en cliquant ici",
	UPGRADENOW: "METTRE À JOUR MON PLAN",

	// Plugin datatable clients
	Leaveworkspace: "Quitter l'espace de travail",
	Nodatatodisplay: "Aucune donnée à afficher",

	MappingDesc: "Cartographiez et associez vos champs avec l'interface de qualification",
	AlertDesc: "Recevez une notification dès qu’un nouveau lead est généré",
	welcomemailDesc: "Engagez vos leads en envoyant un mail de bienvenue instantanné",

	FacebookTokenDesc: "Un jeton est un code secret utilisé pour s'authentifier sur Facebook. Vous pouvez voir et gérer vos jetons Facebook ici",
	SourceName: "Nom de la source",
	Yourpayementwassucceful: "Votre paiement a été bien accepté. Merci",
	Yourpayementwasnotsucceful: "Votre paiement a échoué",
	
	
	// new -------------------
	
	Integration			: 'Destinations',
	CRMs				: 'CRMs',
	LeadEnrichment		: 'Lead Enrichment',
	ESP					: 'ESP',
	VOIP				: 'VoIP',
	
	Integrations		: 'Destinations',
	IntegrationsDesc	: 'Les destinations sont l\'endroit où vous envoyez vos leads après leur qualification. Par exemple, vous pouvez envoyer des leads chauds à Hubspot CRM et des leads froids à Mailchimp pour du Nurturing.',
	ToolSearch			: 'Recherche d\'outils',
	InInfoDesc			: 'Vous ne trouvez pas la destination souhaitée?',
	LetUsknow			: 'Faites-le-nous savoir',
	LEPSearch			: 'Trouvez votre outil d\'enrichissement de leads',
	EspPSearch			: 'Trouvez votre ESP',
	VoipPSearch			: 'Trouvez votre VoIP',
	CrmPSearch			: 'Trouvez votre CRM',
	
	
	SelectForm		: 'Selectionnez un Formulaire',
	ImportFile		: 'Importer fichier',
	MappingFields	: 'Mapping des champs',
	
	PopupDTitle		: 'Salut!',
	PopupDMessage	: "Laissez-nous vous montrer comment qualifier vos leads",
	PopupDButton	: 'Commencez avec des données de démonstration',
	
	ConfigWLDesc	: 'Pour changer ce sous-domaine, cliquez sur le bouton Supprimer ci-dessus pour supprimer cette configuration et en créer une nouvelle.',
	SaveDomain		: 'Enregistrer',
	IveDoneThisStep	: 'J\'ai effectué cette étape',
	CustomizeLogo	: 'Votre logo',
	CustomizeEmail	: 'Votre e-mail',
	
	H5ModalWL		: 'Votre configuration marque blanche est enregistrée',
	SmallModalWL	: 'Nous vous conseillons de prévisualiser votre configuration en marque blanche avant de partager Ohmylead avec vos clients. Contactez le support si vous avez besoin d\'aide',
	TakeALookWL		: 'Prévisualisez votre outil en marque blanche',
	DomainVerfWL	: 'La vérification du nom de sous-domaine peut prendre un peu de temps',
	DoNotPanicWL	: '',

	//added 6 Dec
	WLDesc			: "A partir d'aujourd'hui, vous avez un nouveau service à offrir à vos clients. En tant qu'agence partenaire, vous pouvez fournir la technologie Ohmylead à vos clients sous votre propre sous-domaine et votre propre marque.",
	WLLogoDesc		: 'Choisissez une taille de fichier png ou jpg de 300 x 100 ou plus.',
	WLFavIconDesc	: 'Choisissez une taille de fichier png ou jpg 50x50.',
	WLEmailDesc		: 'Ohmylead permet d\'envoyer des notifications par e-mail lorsque de nouveaux leads sont reçus. Personnalisez ici le nom et l\'adresse de l\'expéditeur de ces e-mails de notification',

	AnalyticsFunnel	: 'Votre entonnoir en trois étapes: 1. le nombre total de leads 2. les leads traités  3. les leads qualifiés (voir les paramètres des statuts des leads)',

	iProcessingRate : 'Leads avec au moins un changement de statut / nombre total de prospects',
	iQualificationRate : 'Leads avec le statut Qualifié / Total des leads',
	iShowStatus		: 'Afficher ou masquer cette option de statut dans la vue détaillée des prospects',
	iIsQualified	: 'Comptabiliser ce statut comme qualifié dans les statistiques',
	iDefault		: 'Le statut sélectionné sera affiché par défaut dans le filtre au niveau de mes leads',
	SorryQuota		: 'Désolé, vous avez dépassé votre quota en terme de nombre de leads',
	SelectAll : 'Selectionnez tout',
	Id : 'Id',
	ProcessingRate : 'Taux de traitement',
	QualificationRate : 'Taux de qualification',

	//analytics
	Overview : "Vue d'ensemble",
	TopSources : "Meilleures Sources",
	topFbC : "Meilleures Campagnes Facebook",
	QualifiedLeads : 'Leads qualifiés',
	Campaign : 'Campagne',
	Adset : 'Adset',
	Ads : 'Annonces',


	AcceptInvitation : 'Accepter l\'invitation',
	IgnoreInvitation : 'Ignorer l\'invitation',
	SendInvitation :'Envoyer l\'invitation',
	InviteNewTeamMember : 'Inviter un nouveau membre',

	//roles
	hasFull : 'A un accès complet pour afficher et modifier toutes les informations du compte, y compris les leads, les sources et les paramètres',
	hasView : 'A un accès en lecture seule à toutes les informations du compte',
	hasFullTwo : 'A un accès complet pour afficher et modifier toutes les informations de compte.',
	Invited : 'Invité',
	ProcessedLeads : 'Leads traités',
	ConnectSource : 'Connecter votre source de leads',

	// new 17/03/2021
	
	TypTtheNewPassword : 'Tapez le nouveau mot de passe',
	sendMessage :'Envoyer Message',
	sendEmail :'Envoyer E-mail',
	Language : 'Langue',
	Yoursourcenamehere : 'Le nom de la source ...',




	//Webhook

	Copylink : "Copier le lien",
	Method : "Méthode",
	Link : "Lien",
	Input : "Mapping",
	InputMapping : "<div><b>Les champs prédéfinis sont :</b></div><div>First_name si le champ 'Prénom' existe</div><div>Last_name si le champ 'Nom' existe</div><div>Full_name si le champ 'Non complet' existe</div><div>Email si le champ 'Email' existe </div><divTelephone si le champ 'Téléphone' existe</div><div>Vous avez la possibilité de choisir des champs supplémentaires qui seront enregistrés sous forme de champs personnalisés.</div>",

	engageYourLead : 'Engagez vos Leads en envoyant un e-mail de bienvenue',
	Enabled : 'Activé',
	Disabled : 'Désactivé',
	NoCardNeeded : 'Aucune carte bancaire requise.',
	Processed : 'Traités',
	Qualified : 'Qualifiés',
	Alla : '',
	thisIsTheLink : 'Voici le lien de l\'invitation à votre éspace de travail',
	linkDesc : 'Ou copiez ce lien et envoyez-le à la personne que vous souhaitez inviter.',
	copyButton : 'Copiez le lien',
	InvitationLink : 'Lien d\'invitation',
	ThisMemberCanAccessTo : 'Ce membre peut accéder à',
	// permissions 30/06.2021
	hasCustom : 'Les permissions personnalisées vous permettent de définir les accès à attribuer aux utilisateurs de votre workspace',
	Editor : 'Éditeur',
	Viewer : 'Lecteur',
	Admin : 'Admin',
	Custom : 'Personnalisé',
	editor : 'Éditeur',
	viewer : 'Lecteur',
	admin : 'Admin',
	custom : 'Personnalisé',
	account_owner : 'Propriétaire',
	Noaccess : 'Vous n\'avez pas les autorisations nécessaires pour accéder à cette page',

	apply : 'Postuler',
	cancel : 'Annuler',
	today : 'Aujourd\'hui',
	yesterday : 'Hier',
	thismonth : 'Ce mois',
	thisyear : 'Cette année',
	lastmonth : 'mois dernier',

	sun : 'Dim',
	mon : 'Lun',
	tue : 'Mar',
	wed : 'Mer',
	thu : 'Jeu',
	fri : 'Ven',
	sat : 'Sam',

	w : 'S',

	jan : 'Jan',
	feb : 'Févr',
	mar : 'Mar',
	apr : 'Avr',
	may : 'Mai',
	jun : 'Jun',
	jul : 'Jul',
	aug : 'Aoû',
	sep : 'Sep',
	oct : 'Oct',
	nov : 'Nov',
	dec : 'Déc',

	/*
	|--------------------------------------------------------------------------
	| Webhook destination
	|--------------------------------------------------------------------------
	*/
	Connect_your_destination : 'Connectez votre destination',
	Create_new_destination : 'Créez une nouvelle destination',
	your_web_hook_destination_updated_successfully : 'Votre destination a été mise à jour avec succès',
	your_web_hook_destination_created_successfully : 'Votre destination a été créée avec succès',
	Request_parameters : 'Paramètres de la requête',
	Sources_where_to_apply : 'Sources',
	When_lead_status_changed_to : 'Lorsque le statut du lead passe à',
	Request_Query : 'Paramètres de la requête',
	Request_headers : 'En-tête de la requête',
	Request_Method : 'Méthode de la requête',
	Request_URL : 'Lien de la requête',
	Name_of_destination : 'Nom de la destination',
	Active : 'Activé',
	Destination_via_Webhook : 'Destination via Webhook',
	New_Destinations : 'Nouvelle destination',
	Map_the_landing_form_fields_with_the_fields_in_our_server : ' Mappez les champs du formulaire avec les champs Ohmylead .', 
	Pick_statuses : 'Choisissez les status ',
	Pick_sources : 'Choisissez les sources',
	Your_name_of_destination_here : 'Nom de la destination',
	Your_endPoint_of_destination_here : 'Lien de la destination',
	My_destinations : 'Mes destinations',
	destinations : 'Destinations',
	Type : 'Tapez',
	Created_at : 'Créé le ',
	The_name_is_required : 'Le nom est obligatoire',
	Enter_a_valid_url_endpoint_for_your_destination : 'Saisissez une URL endpoint valide pour votre destination',
	Choose_a_request_method : 'Choisissez la méthode de la requête ',
	Choose_at_least_1_source : 'Choisissez au moins une source',
	Choose_at_least_1_status : 'Choisissez au moins un statut',
	Key : 'Clé',
	Value : 'Valeur',
	Cant_find_the_integration_you_want: 'Vous ne trouvez pas l\'intégration souhaitée', 
	Let_us_know_here: 'Faites-le-nous savoir ici',
	Nothing_here_yet: 'Aucune destination',
	Nothing_smtp_yet: 'Aucun fournisseur',
	Remove: 'Supprimer',

	desc_workspace: "Vous pouvez gérer plusieurs clients au sein du même compte Ohmylead, avec une séparation totale des leads, des sources de leads et des membres de votre équipe.",
	desc_leadStatus: "Les statuts des leads représentent la relation actuelle d'un lead avec votre entreprise. Vous remarquerez que nous avons préchargé dans votre compte Ohmylead des statuts de leads couramment utilisés comme exemples. Ils peuvent être modifiés pour refléter le processus de gestion des leads spécifique à votre équipe.",
	desc_member: "Vous pouvez ajouter des utilisateurs en leur envoyant simplement une invitation et en sélectionnant le rôle que vous souhaitez leur attribuer.",
	desc_emailTemplate: "Si vous vous retrouvez à envoyer des e-mails similaires à plusieurs reprises, vous pouvez utiliser la fonction de modèles d'e-mails.",
	desc_smtp: "Configurez un service d'envoi d'e-mails SMTP pour garantir une bonne délivrabilité (si vous n'avez pas encore de service SMTP avec les informations d'identification). N'importe quel service d'envoi d'email SMTP peut être utilisé.",

	Nothing_template_yet: "Aucune template",

	/*
	|--------------------------------------------------------------------------
	| Leads details
	|--------------------------------------------------------------------------
	*/
	sendEmail_config_msg: "Configurez un service d’envoi d’e-mails SMTP pour garantir une bonne délivrabilité.",
	Config_from_here: "Cliquez ici.",
	msgCreateWorkspace: "Créez un nouvel espace de travail pour utiliser cette fonctionnalité",
	create: "CRÉEZ",



	RETRY:'Réessayez',
	NAME:'NOM',
	AssignedTo:'Attribuer à',
	Members2:'Utilisateurs',
	Assign:'Assign',
	SaveView:'Créez une vue',
	calendlyNoResult:'Planifier des rendez-vous et réunions automatiquement à travers Ohmylead',
	EmailSentTo:'Email envoyé a',
	BodyHere:'Object ...',
	ShowMore:'Voir plus',
	please_confirm:'Merci de confirmer',

	SMART_VIEWS:'SMART VIEWS' ,
	Unassigned:'Non attribué',
	copied:'Copié',
	cantCopy:'Can not copy..',

	Add_view:'Créer vue',
	edit_view:'Modifier vue',

	fieldNotRequired:'Champ non requis !',
	statusChanged:'Le statut de votre lead a été modifié',
	leadDeleted:'Votre lead a été supprimé',
	leadsDeleted:'Vos leads ont été supprimées',
	fieldRequired:'Champ requis !',
	dataNotFound:'Données non trouvées!',
	emailPhoneMustValid:'Email ou numéro de téléphone doit être valide !',
	mustEnterName:'Vous devez saisir un nom',
	leadSentToWorkspace:'Votre lead a été envoyé à votre espace de travail',
	mustChooseMember:'Vous devez choisir un utilisateur',
	leadAssignedToMember:'Votre lead a été attribrué avec succès',
	chooseWorkspace:'Choisissez votre workspace',
	chooseMember:'Choisissez un membre',

	leadHasBeenSent:'Votre lead a été envoyé',
	leadsHaveBeenSent:'Vos leads ont été envoyés',
	yourSmartViewSent:'Votre smart view a été crée',

	emailSentLead:'Votre email a été envoyé',
	noteAdded:'Votre note a été ajouté',
	LeadAccess:'Accès aux leads',



	emailFormRequired:'Remplir le champs d’email',
	domainNameRequired:'Non du domaine requis',
	mustChooseLogoFavicon:'Ajoutez votre logo et favicon',
	Owner:'Propriétaire',
	youCanConfigCalendly:'Vous pouvez configurer votre Calendly ici',
	calendlyKey:'Clé de calendly ...',
	getYourApiKey:'Obtenez votre clé d’API Calendly ici',


	newOML : 'Boarding time, get started with Ohmylead',
	singleSource : 'Before going any further, we would recommend you check out our walkthrough video.',
	stillHaveQuestion : 'Still have questions about Oh My Lead ?',
	bookFree : 'Book a FREE live demo!',
	now_lead : 'Maintenant',

	API: 'VAPI',
	getVapiApiKey: 'Obtenir votre clé API',
	vapiApiKey: 'Clé API VAPI',
	vapiAssistantId: 'ID de l\'assistant',
	vapiNumberId: 'ID du numéro',
	ConnectionError: 'Erreur de connexion',
	Update: 'Mettre à jour',
    AllFieldsRequired: 'Tous les champs sont requis',
	Validate: "Valider",
	keyValidated: "La clé API a été validée avec succès",
	invalidApiKey: "Clé API invalide",
	validationError: "Erreur lors de la validation de la clé API",
	keyValidatedAndSaved: "La clé API a été validée et enregistrée avec succès",
	UpdateError: "Erreur lors de la mise à jour des paramètres",
	api_key_valid_and_saved: "Clé API validée et enregistrée avec succès",
	no_api_key_found: "Aucune clé API trouvée",

    confirmDeleteSettings: "Êtes-vous sûr de vouloir supprimer ces paramètres ?",
    DeleteError: "Erreur lors de la suppression des paramètres",
    settingsDeleted: "Les paramètres ont été supprimés avec succès",
	AIVoice: 'Voix IA',

	ComingSoon: "Bientôt disponible",
	RitelIntegrationText: "L'intégration Ritel sera disponible prochainement pour améliorer vos capacités vocales AI.",
	SynthFlowIntegrationText: "L'intégration SynthFlow sera disponible prochainement pour automatiser vos flux de travail vocaux.",
	
	StatusActive: "Actif",
	StatusInactive: "Inactif",
	ToggleError: "Erreur lors du changement d'état",
	ritelApiKey: "Clé API Ritel",
	synthflowApiKey: "Clé API SynthFlow",
	StatusActive: "Actif",

	Status: "Statut",
	Summary: "Résumé",
	Transcript: "Transcription",
};

export default Fr;
